<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<div slot="header" class="calendar-head">
				<div class="title">{{ $t('seller.campaign.calendar') }}</div>
				<div class="time">
					<i class="el-icon-arrow-left" @click="handleCalendar(-3)"></i>
					<span>{{ calendarTime | parseTime('{y}年{m}月')}} ~ {{ calendarDatePicker(calendarTime, 2) | parseTime('{y}年{m}月')}}</span>
					<i class="el-icon-arrow-right" @click="handleCalendar(3)"></i>
				</div>
				<div class="next">
					<!-- <a href="javascript:void(0);" class="link">使用说明</a> -->
				</div>
			</div>
			<div class="calendar-main">
				<div class="calendar-panel" v-for="item in calendarList" :key="item.month">
					<div class="panel-head">
						<div class="month">{{ parseInt(item.month) }}{{ $t('seller.unit.month') }}</div>
						<div class="number">{{ item.list.length }}</div>
					</div>
					<div class="panel-list">
						<div class="item" v-for="child in item.list" :key="child.activity_id">
							<div class="item-head">
								<div class="item-tags">
									<el-tag type="info" size="mini"># {{ child.label_name }}</el-tag>
									<el-tag :type="child.a_state | typeFilter({1:'success',2: 'success',3: 'success',4:'warning',5:'danger',6:'info'})" size="mini">
										<svg-icon :icon-class="child.a_state | typeFilter({1:'medal_fill',2: 'medal_fill',3: 'medal_fill',4:'broadcast_fill',5:'hot_fill',6:'activity_fill'})"></svg-icon>{{ child.a_state | typeFilter($t('seller.options.campaignStatusTags')) }}
									</el-tag>
								</div>
								<div class="name"><router-link :to="{ name: 'campaignDetail', params: {id: child.activity_id }}">{{ child.name }}</router-link></div>
							</div>
							<div class="item-main">
								<div class="item-row">
									<div class="row-head"><span class="head-label"><i class="el-icon-mobile-phone"></i>{{ $t('seller.campaign.rhythm') }}</span><span class="head-block"></span></div>
									<div class="row-main">
										<div class="row-cell">
											<div class="cell-label">{{ $t('seller.campaign.shopSignUp') }}</div>
											<div class="cell-block" v-if="child.a_state > 1">{{ child.a_state !== 2 ? $t('seller.campaign.shopSignUpEnd') : $t('seller.campaign.shopSignUpProgress') }}<i class="el-icon-arrow-right"></i></div>
											<div class="cell-block" v-else>{{ $t('seller.campaign.shopSignUpNotStarted') }}<i class="el-icon-arrow-right"></i></div>
										</div>
										<div class="row-cell">
											<div class="row-cell">
												<div class="cell-name">{{ $t('seller.campaign.shopSignUp') }}</div>
												<div class="cell-time">{{ child.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ child.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</div>
											</div>
										</div>
										<div class="row-cell">
											<div class="cell-label">{{ child.name }}</div>
											<div class="cell-block" v-if="child.a_state > 1">
												<template v-if="child.goods_enroll_status ===1 && child.a_state===2">{{ $t('seller.campaign.itemSignUpProgress') }}</template>
												<template v-else>
													{{ child.a_state | typeFilter($t('seller.options.campaignStatusText'))}}
												</template>
											</div>
											<div class="cell-block" v-else>{{ $t('seller.campaign.itemSignUpNotStarted') }}</div>
										</div>
										<div class="row-cell">
											<div class="row-cell">
												<div class="cell-name">{{ $t('seller.campaign.itemSignUp') }}</div>
												<div class="cell-time">{{ child.g_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ child.g_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</div>
											</div>
											<div class="row-cell">
												<div class="cell-name">{{ $t('seller.campaign.activityWarmUp') }}</div>
												<div class="cell-time">{{ child.warm_up_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ child.warm_up_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</div>
											</div>
											<div class="row-cell">
												<div class="cell-name">{{ $t('seller.campaign.activitySales') }}</div>
												<div class="cell-time">{{ child.sales_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ child.sales_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="item-row">
									<div class="row-head"><span class="head-label"><i class="el-icon-mobile-phone"></i>{{ $t('seller.campaign.play') }}</span><span class="head-block">{{ $t('seller.campaign.tipsText1') }}</span></div>
								</div>
								<div class="item-row">
									<div class="row-head"><span class="head-label"><i class="el-icon-mobile-phone"></i>{{ $t('seller.campaign.resources') }}</span><span class="head-block">{{ $t('seller.campaign.tipsText1') }}</span></div>
								</div>
							</div>
						</div>
						<div v-if="!item.list.length > 0" class="empty">{{ $t('seller.campaign.tipsText2') }}</div>
					</div>
				</div>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { datePicker } from "@/utils/index"
import { fetchCampaignCalendar } from '@/api/seller/market'
export default {
	data() {
		return {
			calendarTime: new Date(new Date().getFullYear(),new Date().getMonth(), 1),
			calendarList: [],
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData() {
			const params = {}
			var myDate = new Date(this.calendarTime)
			var timeFrom = myDate.getTime()
			var timeTo = datePicker(timeFrom,0,3,0,'') - 1000
			params.time_from = parseInt(timeFrom / 1000)
			params.time_to = parseInt(timeTo / 1000)
			const { data } = await fetchCampaignCalendar(params)
			this.calendarList = data
		},
		calendarDatePicker(time,month) {
			var newTime = datePicker(time,0,month,0,'')
			return newTime
		},
		handleCalendar(month) {
			var myDate = new Date(this.calendarTime)
			myDate.setMonth(myDate.getMonth() + month)
			this.calendarTime = myDate.getTime()
			this.getData()
		},
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.calendar-head {
		display: flex;

		.title {
			flex: 1;
			font-size: 18px;
			color: #333333;
		}

		.time {
			flex: 2;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				color: #666666;
				margin: 0 9px;
			}

			[class^=el-icon-] {
				color: #666666;
				font-weight: bold;
				cursor: pointer;
			}
		}

		.next {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
	.calendar-main {
		display: flex;
		.calendar-panel {
			flex-shrink: 0;
			margin-right: 12px;
			border-radius: 9px;
			width: calc(33.33% - 8px);
			background-color: #ffffff;
			border: 1px solid #f0f2f5;
			&:last-child {
				margin-right: 0;
			}
			.panel-head {
				padding: 0 9px;
				height: 48px;
				display: flex;
				opacity: .5;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #f0f2f5;
				.month {
					font-size: 14px;
					font-weight: bold;
					line-height: 48px;
					color: #111111;
				}
				.number {
					width: 22px;
					line-height: 22px;
					font-size: 12px;
					color: #666666;
					border-radius: 24px;
					text-align: center;
					border: 1px solid #e3e5e7;
				}
			}
			.panel-list {
				padding: 12px;
				.item {
					margin-bottom: 12px;
					border-radius: 12px;
					overflow: hidden;
					background-color: rgb(247, 248, 250);
					.item-head {
						padding: 12px;
						background-color: rgba(0, 0, 0, 0.02);
						.item-tags {
							margin-bottom: 4px;
						}
						.el-tag {
							margin-right: 6px;
							border-radius: 10px;
							padding: 0 9px;
							border-color: transparent;
						}
						.name {
							color: #111111;
							font-size: 14px;
							line-height: 20px;
							a {
								color: inherit;
							}
						}
					}
					.item-main {
						padding: 6px 12px;
						font-size: 12px;
						line-height: 1.5;
						display: flex;
						flex-direction: column;
						.item-row {
							margin: 6px 0;
							.row-head {
								display: flex;
								justify-content: space-between;
								.head-label {
									color: rgb(153, 153, 153);
									[class^=el-icon-] {
										margin-right: 4px;
									}
								}
							}
							.row-main {
								.row-cell {
									margin-left: 6px;
									padding: 4px 0 4px 12px;
									display: flex;
									flex-flow: wrap;
									justify-content: space-between;
									border-left: 1px solid rgb(230, 232, 237);
									.cell-label {
										color: rgb(17, 17, 17);
									}
									.cell-block {
										cursor: pointer;
										color: #666666;
										&:hover {
											color: $--seller-primary;
										}
									}
									.row-cell {
										width: 100%;
										flex-shrink: 0;
										color: rgb(153, 153, 153);
										display: block;
									}
								}
							}
						}
					}
					&:hover {
						.item-head {
							.name {
								a {
									color: $--seller-primary;
								}
							}
						}
					}
				}
				
				.empty {
					font-size: 14px;
					color: rgb(204, 204, 204);
					text-align: center;
				}
			}
			
			&:hover {
				.panel-head {
					opacity: 1;
				}
			}
		}
	}
}
::v-deep .el-tag {
	&.el-tag--info {
		color: #111111;
		background-color: #e0e4eb;
	}
	[class^=el-icon-] {
		margin-right: 4px;
	}
}
</style>